export class Search {
	constructor(search = "", serachBtn = "") {
		this.search = search;
		this.searchBtn = serachBtn;
		this.activationClass = "open";

		this.listeners();
	}

	listeners() {
		this.searchBtn.addEventListener("click", this.toggleSearch.bind(this));
	}

	toggleSearch(ev) {
		ev.preventDefault();
		this.search.classList.toggle(this.activationClass);
	}
}
