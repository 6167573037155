import { tns } from "tiny-slider/src/tiny-slider.js";
const gallery = document.querySelector(".gallery");

if (gallery) {
  const slider = tns({
    container: document.querySelector(".gallery"),
    items: 1,
    slideBy: "page",
    mouseDrag: true,
    prevButton: ".prev-button",
    nextButton: ".next-button",
    autoplayButton: ".pause-button",
    lazyload: true,
    lazyloadSelector: ".gallery-image",
    speed: 500,
    autoplayTimeout: 3000,
    autoplay: true,
    nav: false
  });
}
