import { isMobile } from "./helper";
import { toggleMenu, closeMenu } from "./navigation";
import { Search } from "./searchform";

function cookiesPolicyBar() {
	if (getCookie("acceptedCookies") != "active") {
		document.getElementById("cookieAcceptBar").classList.add("active");
	}
	document
		.querySelector("#cookieAcceptBarConfirm")
		.addEventListener("click", () => {
			setCookie("acceptedCookies", "active", 10);
			document.getElementById("cookieAcceptBar").classList.remove("active");
		});
}

cookiesPolicyBar();

window.addEventListener("load", () => {
	toggleMenu();

	if (isMobile()) {
		closeMenu();
	}
});

window.onresize = () => {
	if (isMobile()) {
		closeMenu();
	}
};

const search = new Search(
	document.querySelector(".SearchForm"),
	document.querySelector("#searchBtn")
);

function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = "expires=" + d.toUTCString();
	document.cookie =
		cname + "=" + cvalue + ";" + expires + ";path=/" + ";SameSite=Lax";
}

function getCookie(cname) {
	const name = cname + "=";
	const ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

const contrastView = document.getElementById("contrast-view");
const fontSize = document.getElementById("font-size");

fontSize.addEventListener("click", (e) => {
	e.preventDefault();
	if (!getCookie("fontSize")) {
		setCookie("fontSize", "1", 10);
		window.location.reload(false);
	} else {
		setCookie("fontSize", "0", -10);
		window.location.reload(false);
	}
});

const fontSizeCss = document.getElementById("font-size-css");
if (fontSizeCss && !getCookie("fontSize")) {
	wcagcss.parentNode.removeChild(wcagcss);
}

if (getCookie("fontSize") && !fontSizeCss) {
	const link = document.createElement("link");
	link.href = `${window.location.origin}/wp-content/themes/pgk/dist/css/font-size.css`;
	link.id = "font-size-css";
	link.rel = "stylesheet";
	link.type = "text/css";
	link.media = "all";
	document.head.appendChild(link);
}

contrastView.onclick = (e) => {
	e.preventDefault();
	if (!getCookie("wcag")) {
		setCookie("wcag", "1", 10);
		window.location.reload(false);
	} else {
		setCookie("wcag", "0", -10);
		window.location.reload(false);
	}
};

document.addEventListener("keydown", function(event) {
	if (event.altKey && event.code === "Digit1") {
		event.preventDefault();
		if (!getCookie("fontSize")) {
			setCookie("fontSize", "1", 10);
			window.location.reload(false);
		} else {
			setCookie("fontSize", "0", -10);
			window.location.reload(false);
		}
	}

	if (event.altKey && event.code === "Digit2") {
		event.preventDefault();
		if (!getCookie("wcag")) {
			setCookie("wcag", "1", 10);
			window.location.reload(false);
		} else {
			setCookie("wcag", "0", -10);
			window.location.reload(false);
		}
	}
});

const wcagcss = document.getElementById("wcag-css");
if (wcagcss && !getCookie("wcag")) {
	wcagcss.parentNode.removeChild(wcagcss);
}

if (getCookie("wcag") && !wcagcss) {
	const link = document.createElement("link");
	link.href = `${window.location.origin}/wp-content/themes/pgk/dist/css/wcag.css`;
	link.id = "wcag-css";
	link.rel = "stylesheet";
	link.type = "text/css";
	link.media = "all";
	document.head.appendChild(link);
}
