//js
require("babel-polyfill");
require("./js/slider");
require("./js/helper");
require("./js/navigation");
require("./js/main");

//fonts
// require('./fonts/font/stylesheet.css');

// css
import "normalize.css";
require("./sass/style.scss");
require("tiny-slider/src/tiny-slider.scss");
